import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const store = new Vuex.Store({
  state: {
    activeIndex: "0"
  },
  mutations: {
    SET_ACTIVE: (state, index) => {
      state.activeIndex = index
    },
  }
})

export default store
