import Vue from 'vue'
import VueRouter from 'vue-router'
import store from "@/store"
import {routeList} from "@/utils"

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  routes: [{
    path: '/',
    redirect: 'main'
  }, {
    path: '/main',
    component: () => import('@/views/main/index'),
    redirect: 'home',
    children: [
      {
        path: '/home',
        component: () => import('@/views/home/index'),
      },
      {
        path: '/histopathology',
        component: () => import('@/views/histopathology/index'),
      },
      {
        path: '/immunohistochemistry',
        component: () => import('@/views/immunohistochemistry/index'),
      },
      {
        path: '/histochemistry',
        component: () => import('@/views/histochemistry/index'),
      },
      {
        path: '/analysis',
        component: () => import('@/views/analysis/index'),
      },
      {
        path: '/equipment',
        component: () => import('@/views/equipment/index'),
      }
    ]
  }]
});

// 全局监听路由
router.beforeEach((to, from, next) => {
  let data = routeList.find(item => `/${item.url}` === to.path)
  store.commit('SET_ACTIVE', data.key)
  next();
});

export default router
