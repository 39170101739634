export let routeList = [{
  url: 'home',
  key: '0'
}, {
  url: 'histopathology',
  key: '1'
}, {
  url: 'immunohistochemistry',
  key: '2'
}, {
  url: 'histochemistry',
  key: '3'
}, {
  url: 'analysis',
  key: '4'
}, {
  url: 'equipment',
  key: '5'
}]
